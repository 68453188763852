export default (app) => {
  app.mixin({
    mounted() {
      this.emitter.on("update-all", this.updateAll);
    },
    unmounted() {
      this.emitter.off("update-all", this.updateAll);
    },
    methods: {
      updateAll() {
        this.$forceUpdate();
      },
    },
  });
};
