import { createStore } from "vuex";
import storeGlobal from "./global";
import storeApp from "./app";
import storeAuth from "./auth";
import storeAccount from "./account";

export default createStore({
  state: {
    ...storeGlobal.state,
    ...storeAuth.state,
    ...storeAccount.state,
    ...storeApp.state,
  },
  getters: {
    ...storeGlobal.getters,
    ...storeAuth.getters,
    ...storeAccount.getters,
    ...storeApp.getters,
  },
  mutations: {
    ...storeGlobal.mutations,
    ...storeAuth.mutations,
    ...storeAccount.mutations,
    ...storeApp.mutations,
  },
  actions: {
    ...storeGlobal.actions,
    ...storeAuth.actions,
    ...storeAccount.actions,
    ...storeApp.actions,
  },
  modules: {},
});
