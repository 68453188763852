import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "account",
    meta: { requireLogin: true },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/account"),

    children: [
      {
        path: "profile",
        name: "account-profile",
        component: () =>
          import(
            /* webpackChunkName: "account-profile" */ "../views/account/pages/profile"
          ),
      },
      {
        path: "payments",
        name: "account-payments",
        component: () =>
          import(
            /* webpackChunkName: "account-payments" */ "../views/account/pages/payments"
          ),
      },
      {
        path: "address",
        name: "account-address",
        component: () =>
          import(
            /* webpackChunkName: "account-address" */ "../views/account/pages/address"
          ),
      },
      {
        path: "orders",
        name: "account-orders",
        component: () =>
          import(
            /* webpackChunkName: "account-orders" */ "../views/account/pages/orders"
          ),
      },
      {
        path: "orders/:orderID",
        name: "account-orders-details",
        component: () =>
          import(
            /* webpackChunkName: "account-orders-details" */ "../views/account/pages/orders-details"
          ),
      },
      {
        path: "wishlist",
        name: "account-wishlist",
        component: () =>
          import(
            /* webpackChunkName: "account-wishlist" */ "../views/account/pages/wishlist"
          ),
      },
    ],
  },

  {
    path: "/verify",
    name: "auth-verify",
    component: () =>
      import(/* webpackChunkName: "auth-verify" */ "../views/verify"),
  },

  {
    path: "/login",
    name: "auth-login",
    component: () =>
      import(/* webpackChunkName: "auth-login" */ "../views/login"),
  },

  {
    path: "/recovery-password",
    name: "recovery-password",
    component: () =>
      import(
        /* webpackChunkName: "recovery-password" */ "../views/recovery-password"
      ),
  },

  {
    path: "/signup",
    name: "auth-signup",
    component: () =>
      import(/* webpackChunkName: "auth-signup" */ "../views/signup"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
