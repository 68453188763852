export default {
  state: {
    app: {},
  },
  getters: {
    getApp: (state) => state.app,
    getLoadingApp: (state) => state.loadingApp,
  },
  mutations: {
    SET_APP: (state, value) => (state.app = value),
  },
  actions: {
    async searchApp({ commit, dispatch }) {
      const params = new URLSearchParams(window.location.search);

      try {
        const id = params.get("app") ?? window.$getCookie("app");
        const time = new Date().getTime();
        const response = await fetch(
          `${process.env.VUE_APP_API_STORAGE}applications/ecommerce/${id}.json?t=${time}`
        ).then((data) => {
          return data.json();
        });

        commit("SET_APP", response);
        dispatch("setPlatform", response);
        dispatch("setColorsTheme", response);

        window.$setCookie("app", id);
        window.$setCookie("language", response.i18n.default_language);
      } catch (error) {
        console.log("[searchApp]", error);
      } finally {
        window.$setCookie("app", params.get("app"));
      }
    },

    setPlatform({ commit }, app) {
      let platform = "";

      if (app.platform?.name === "magento") {
        platform = window.$setCookie(
          "platform",
          "magento" + app.platform?.version
        );

        console.log(commit);
      } else {
        platform = window.$setCookie("platform", app.platform?.name);
      }
      window.$platform = platform;
    },

    setColorsTheme({ commit }, app) {
      let primaryColor = app?.appearance.colors?.primary || "#000";
      const root = document.documentElement;

      root.style.setProperty("--primary", primaryColor);
      console.log(commit);
    },
  },
  modules: {},
};
