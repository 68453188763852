import { mapActions, mapGetters } from "vuex";

export default (app) => {
  app.mixin({
    computed: {
      ...mapGetters({
        $user: "getUser",
        $app: "getApp",
        $setCookie: "setCookie",
        $getCookie: "getCookie",
        $eraseCookie: "eraseCookie",
      }),
    },
    methods: {
      ...mapActions({
        $logout: "logout",
      }),
    },
  });
};
