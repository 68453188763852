<template>
  <router-view />

  <div v-if="getFirstLoadig" class="first-loading" data-grid="center-center">
    <figure class="first-loading-figure" data-grid="center-center">
      <img :src="$app.appearance?.image" :alt="$app?.app?.name" />
    </figure>
  </div>

  <div
    v-if="globalLoading"
    class="inc-global__loading"
    data-grid="center-center"
  >
    <div class="inc-loader--linear">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <Toast />
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Toast from "@/components/toast";

export default defineComponent({
  components: {
    Toast,
  },

  async mounted() {
    await this.searchApp();
    await this.auth();

    // if (Object.keys(this.$user || {}).length > 0) {
    //   console.log("entrou aqui"), this.$route;
    //   const validRoute = [
    //     "account-profile",
    //     "account-payments",
    //     "account-address",
    //     "account-orders",
    //     "account-wishlist",
    //   ].includes(this.$route?.name)
    //     ? this.$route.name
    //     : "account-profile";

    //   this.$router.push({
    //     name: validRoute,
    //     query: {
    //       app: this.$route.query?.app || window.$getCookie("app"),
    //     },
    //   });
    // }
  },

  computed: {
    ...mapGetters({
      globalLoading: "getLoading",
      getFirstLoadig: "getFirstLoading",
    }),
  },

  watch: {
    $user: {
      deep: true,
      handler(u) {
        if (Object.keys(u || {}).length <= 0) {
          this.$router.push({
            name: "auth-verify",
            query: {
              app: this.$route.query?.app || window.$getCookie("app"),
            },
          });
        }
      },
    },
  },

  methods: {
    ...mapActions({
      auth: "auth",
      searchApp: "searchApp",
    }),
  },
});
</script>

<style lang="scss">
@import "@/scss/global";
</style>
