import { mapGetters, mapActions } from "vuex";

export default (app) => {
  app.mixin({
    computed: {
      ...mapGetters({
        $filters: "getFilters",
      }),
    },

    methods: {
      ...mapActions({
        __setFilter: "setFilter",
        __setFilters: "setFilters",
        __removeFilters: "removeFilters",
      }),

      getFilters(slot) {
        return this.$filters[slot] || {};
      },

      setFilter(path, value, toggle = false) {
        const [slot, name] = path.split(".");
        this.__setFilter({ slot, name, value, toggle });
        this.$forceUpdate();

        this.emitter.emit(`${slot}-filter-changed`, name, value);
      },

      setFilters(slot, value) {
        this.__setFilters({ slot, value });
        this.$forceUpdate();

        this.emitter.emit(`${slot}-filter-changed`, slot, value);
      },

      removeFilters(slot, value) {
        this.__removeFilters({ slot, value });
        this.$forceUpdate();

        this.emitter.emit(`${slot}-filter-changed`, slot, value);
      },
    },
  });
};
