export default (router) => {
  router.beforeEach((to, from, next) => {
    window.$route = to;

    const params = new URLSearchParams(window.location.search);
    if (params.get("token")) {
      window.$setCookie("token", params.get("token") || "");
    }

    if (to.matched.some((record) => record.meta.requireLogin)) {
      if (!window.$getCookie("token") || window.$getCookie("token") == "null") {
        return next({
          name: "auth-verify",
          params: { ...to.params },
          query: { ...from.query },
        });
      }
    }

    next();
  });
};
