<template>
  <div
    :class="{ 'inc-toast--showed': visible, [`inc-toast--${color}`]: true }"
    class="inc-toast"
    data-grid="nowrap"
  >
    <p>
      {{ message || "" }}
    </p>
    <span @click="close">✕</span>
  </div>
</template>

<script>
export default {
  data: () => ({
    message: "",
    closable: true,
    color: "default",
    time: 4000,
    visible: false,
  }),

  mounted() {
    this.emitter.on("on-toast-show", this.show);
    window.$toast = this.show;
  },

  unmounted() {
    this.emitter.off("on-toast-show", this.show);
  },

  methods: {
    /**
     * Mostra o toast, mas antes verifica se ele está fechado,
     * caso esteja aberto ele termina a exibição do ativo para
     * depois exibir o novo
     */
    show({ color, message, time = 4000, closable = true }) {
      let delay = 10;
      if (this.visible) {
        this.close();
        delay = 600;
      }

      setTimeout(() => {
        this.color = color;
        this.message = message;
        this.time = time;
        this.closable = closable;
        this.visible = true;

        setTimeout(this.close, time);
      }, delay);
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.inc-toast {
  position: fixed;
  display: flex;
  bottom: 20px;
  left: -100vw;
  border-radius: 4px;
  background: rgb(57, 71, 84);
  box-shadow: 0 0 20px -5px rgb(0 0 0 / 22%);
  justify-content: space-between;
  align-items: center;
  transition: 1s;
  z-index: 999;
  overflow: hidden;
  padding: 15px 10px;
  max-width: 80vw;
  min-height: fit-content;
  width: max-content;
  justify-content: space-between;

  &.inc-toast--showed {
    left: 20px;
  }

  p {
    padding-right: 10px;
    font-size: 14px;
    color: #fff;
    text-align: left;
  }

  span {
    right: 0;
    user-select: none;
    cursor: pointer;
    height: 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 22px;
    font-size: 12px;
    font-weight: bold;
  }

  &.inc-toast--blue {
    background: #0090e0;
  }

  &.inc-toast--success {
    background: #34c240;
  }

  &.inc-toast--yellow {
    background: rgb(205, 209, 20);
  }

  &.inc-toast--alert {
    background: #fa9f47;
  }

  &.inc-toast--error {
    background: #d24242;
  }
}

@media (max-width: 1024px) {
  .inc-toast {
    width: max-content;
    height: max-content;
  }
}
</style>
