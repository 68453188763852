export default (app) => {
  app.mixin({
    methods: {
      $currency(price) {
        const formatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        return formatter.format(price);
      },
    },
  });
};
