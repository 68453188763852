import axios from "axios";

/**
 * Cria uma instância do axios com as configurações padrões para
 * a API do backend, esse método é armazenado na variável
 * window.$request para ser acessível no Vuex
 */
export default (type = null) => {
  let baseURL = "";

  const params = new URLSearchParams(window.location.search);
  const appID = params.get("app") ?? (window.$route || {}).query?.app;
  const customerToken = params.get("token") ?? window.$getCookie("token");

  let customHeaders = {};

  if (!type) {
    baseURL = process.env.VUE_APP_API_INCREAZY_ECOMMERCE;
    customHeaders = appID ? { App: appID } : {};
  } else if (type === "elastic") {
    baseURL = process.env.VUE_APP_API_SEARCH_ENGINE;
    customHeaders = {
      ...customHeaders,
      Authorization: process.env.VUE_APP_API_SEARCH_ENGINE_TOKEN,
    };
  }

  let headers = {
    Language: window.$getCookie("language") ?? "pt",
    "customer-token": customerToken,
    ...customHeaders,
  };

  return axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: headers,
  });
};
