export default {
  state: {
    user: {},
    token: "",
    loading: false,
    firstLoadig: false,
    attempts: 0,
  },
  getters: {
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getLoading: (state) => state.loading,
    getFirstLoading: (state) => state.firstLoadig,
    getAttempts: (state) => state.attempts,
  },
  mutations: {
    SET_USER: (state, value) => (state.user = value),
    SET_ATTEMPTS: (state, value) => (state.attempts = value),
    SET_TOKEN: (state, value) => {
      state.token = value;
      if (value === "") {
        window.$eraseCookie("token");
      } else {
        window.$setCookie("token", value || "");
      }
    },
    SET_LOADING: (state, value) => (state.loading = value),
    SET_FIRST_LOADING: (state, value) => (state.firstLoadig = value),
  },
  actions: {
    async verify({ commit }, { data, done, fail }) {
      try {
        commit("SET_ATTEMPTS", 0);
        commit("SET_LOADING", true);

        data.isDocument = false;
        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/customer/exists`,
            data
          );

        done(response.data);
      } catch (error) {
        console.log(error, fail);

        const params = new URLSearchParams(window.location.search);
        window.$router.push({
          name: "auth-signup",
          query: { app: params.get("app") },
        });
      } finally {
        commit("SET_LOADING", false);
        window.$setCookie("email", data.input);
        window.$setCookie("app", window.$route.query.app);
      }
    },

    async signupOrUpdate({ commit, dispatch }, { data, done, fail }) {
      try {
        commit("SET_LOADING", true);

        const endpoint = data.is_update == "true" ? "update" : "register";

        if (data.corporate_name) {
          data.name = `${data?.corporate_name} ${data?.fancy_name}`;
          delete data.corporate_name;
          delete data.fancy_name;
        }

        let customer = {
          customer: { ...data },
        };

        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/customer/${endpoint}`,
            customer
          );

        if (endpoint === "register") {
          let login = {
            email: data.email,
            password: data.password,
          };

          dispatch("login", { data: login, done, fail });
        } else {
          window.$toast({
            color: "success",
            message: `Informações alteradas!`,
          });
        }

        window.$setCookie("email", data.email);

        done(response.data);
      } catch (error) {
        fail(
          error?.response.data?.msg || error?.response?.data?.message || error
        );
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async login({ commit, state }, { data, done, fail }) {
      try {
        commit("SET_LOADING", true);

        data.cartId = window.$getCookie("cart-id") || "";

        const response = await window
          .$request()
          .post(
            `/${window.$platform || window.$getCookie("platform")}/auth/login`,
            data
          );

        const { customer, token, token_s } = response.data;

        commit("SET_USER", customer);
        commit("SET_TOKEN", token);

        window.$setCookie("email", customer.email);
        window.$setCookie("token", token || "");

        window.parent.postMessage(
          JSON.stringify({
            event: "login-customer",
            token: token,
            token_s: token_s || null,
          }),
          "*"
        );

        window.$toast({
          color: "success",
          message: `Seja bem vindo(a), ${customer.firstname}!`,
        });

        window.$router.push({
          name: "account-profile",
          query: {
            app: window.$router.query?.app || window.$getCookie("app"),
          },
        });

        done(response.data);

        commit("SET_ATTEMPTS", 0);
      } catch (error) {
        fail(
          error?.response.data?.msg || error?.response?.data?.message || error
        );

        commit("SET_ATTEMPTS", state.attempts + 1);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async auth({ commit }) {
      try {
        if (!(window.$route?.name || "").includes("profile")) {
          commit("SET_FIRST_LOADING", true);
        } else {
          commit("SET_LOADING", true);
        }

        const response = await window
          .$request()
          .post(
            `/${window.$platform || window.$getCookie("platform")}/auth/get`,
            {
              cartId: window.$getCookie("cart-id") || "",
            }
          );

        const { customer, token, token_s } = response.data;

        commit("SET_USER", customer);
        commit("SET_TOKEN", token);

        window.$setCookie("token", token || "");

        window.parent.postMessage(
          JSON.stringify({
            event: "login-customer",
            token: token,
            token_s: token_s || null,
          }),
          "*"
        );
      } catch (error) {
        console.log("[auth]", error);

        commit("SET_TOKEN", "");
        commit("SET_USER", {});
      } finally {
        commit("SET_FIRST_LOADING", false);
        commit("SET_LOADING", false);
      }
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_TOKEN", "");

      window.parent.postMessage(
        JSON.stringify({
          event: "login-customer",
          token: null,
          token_s: null,
        }),
        "*"
      );
    },

    async sendEmailRecovery({ commit }, email) {
      try {
        commit("SET_LOADING", true);

        await window
          .$request()
          .post(
            `${
              window.$platform || window.$getCookie("platform")
            }/auth/forgotPassword`,
            {
              email: email,
              isLogin: false,
            }
          );

        window.$toast({
          color: "success",
          message: "Te enviamos um código por email!",
        });

        window.$router.push({
          name: "auth-recovery-password",
          query: {
            ...window.$router.query,
            app: window.$router.query?.app || window.$getCookie("app"),
          },
        });
        console.log(commit);
      } catch (error) {
        console.log("[sendEmailRecovery]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async recoveryPassword({ commit, dispatch }, { data, done, fail }) {
      try {
        commit("SET_LOADING", true);

        await window
          .$request()
          .post(
            `${
              window.$platform || window.$getCookie("platform")
            }/auth/confirmPassword`,
            data
          );

        let login = {
          email: data.email,
          password: data.password,
        };

        dispatch("login", { data: login, done, fail });

        console.log(commit);
      } catch (error) {
        fail(
          error?.response.data?.msg || error?.response?.data?.message || error
        );
      } finally {
        commit("SET_LOADING", true);
      }
    },
  },
  modules: {},
};
