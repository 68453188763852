import { createApp } from "vue";
import VueTheMask from "vue-the-mask";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/i18n";
import request from "@/core/request";
import mitt from "mitt";
import { useComponents } from "@/core/components";
import { useUtils } from "@/core/utils";
import middlewares from "@/core/middlewares";

window.$request = request;
window.$router = router;

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

middlewares(router, store);
useComponents(app);
useUtils(app);

app.use(VueTheMask);
app.use(store);
app.use(router);
app.use(i18n);
app.mount("#app");
