import { defineAsyncComponent } from "vue";

/**
 * Objeto de todos componentes globais, de forma dinâmica
 * para serem usados na aplicação
 */
const components = {
  FormInput: defineAsyncComponent(() => import("@/components/form/input")),
  FormRequest: defineAsyncComponent(() => import("@/components/form/request")),
  BtnDefault: defineAsyncComponent(() => import("@/components/btn-default")),
  Pagination: defineAsyncComponent(() => import("@/components/pagination")),
};

/**
 * Import dinâmico de todos os componentes definidos
 */
export const useComponents = (app) => {
  Object.keys(components).forEach((componentName) => {
    app.component(componentName, components[componentName]);
  });
};
