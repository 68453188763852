export default {
  state: {
    orders: [],
    order: {},
    wishlistProducts: [],
    addressList: [],
    cardList: [],
  },
  getters: {
    getOrders: (state) => state.orders,
    getOrder: (state) => state.order,
    getWishlistProducts: (state) => state.wishlistProducts,
    getAddressList: (state) => state.addressList,
    getCardList: (state) => state.cardList,
  },
  mutations: {
    SET_ORDERS: (state, value) => (state.orders = value),
    SET_ORDER: (state, value) => (state.order = value),
    SET_WISHLIST_PRODUCTS: (state, value) => (state.wishlistProducts = value),
    SET_ADDRESS_LIST: (state, value) => (state.addressList = value),
    SET_CARD_LIST: (state, value) => (state.cardList = value),
  },
  actions: {
    async searchOrders({ commit }, params) {
      try {
        commit("SET_LOADING", true);

        const response = await window
          .$request()
          .post(
            `/${window.$platform || window.$getCookie("platform")}/order/list`,
            { ...params }
          );

        commit("SET_ORDERS", response.data);
      } catch (error) {
        console.log("[searchOrders]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async searchOrder({ commit }, data) {
      try {
        commit("SET_LOADING", true);

        const response = await window
          .$request()
          .post(
            `/${window.$platform || window.$getCookie("platform")}/order/get`,
            { id: data }
          );

        commit("SET_ORDER", response.data);
      } catch (error) {
        console.log("[searchOrders]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async searchWishlistProducts({ commit }, userId) {
      try {
        commit("SET_LOADING", true);

        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/wishlist/get`,
            {
              all: true,
              customer_id: +userId,
            }
          );

        commit("SET_WISHLIST_PRODUCTS", response.data);
      } catch (error) {
        console.log("[searchOrders]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async removeWishlist({ commit, dispatch }, data) {
      try {
        commit("SET_LOADING", true);

        await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/wishlist/remove`,
            {
              id: data.id,
              customer_id: data.userId,
            }
          );

        await dispatch("searchWishlistProducts", data.userId);
      } catch (error) {
        console.log("[searchOrders]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async searchAddressList({ commit }, data) {
      try {
        commit("SET_LOADING", true);

        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/address/list`,
            { customer: { ...data } }
          );

        commit("SET_ADDRESS_LIST", response.data);
      } catch (error) {
        console.log("[searchAddressList]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async createOrUpdateAddress({ commit }, { data, done, fail }) {
      try {
        commit("SET_LOADING", true);

        const cartId = window.$getCookie("cart-id");

        if (!data.complement) data.complement = "";
        if (!data.receiver) data.receiver = "";

        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/address/create`,
            { address: { ...data }, cartId }
          );

        commit("SET_ADDRESS_LIST", response.data);

        window.$toast({
          color: "success",
          message: "Endereço criado com sucesso!",
        });

        done(response.data);
      } catch (error) {
        console.log("[createOrUpdateAddress]", error);

        window.$toast({
          color: "error",
          message:
            "Tivemos um problema, tente novamente ou entre em contato conosco.",
        });
        fail(error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async removeAddress({ commit }, data) {
      try {
        commit("SET_LOADING", true);

        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/address/remove`,
            { address: { ...data } }
          );

        commit("SET_ADDRESS_LIST", response.data);

        window.$toast({
          color: "success",
          message: "Endereço removido!",
        });
      } catch (error) {
        console.log("[createOrUpdateAddress]", error);

        window.$toast({
          color: "error",
          message:
            "Tivemos um problema, tente novamente ou entre em contato conosco.",
        });
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async searchCardList({ commit }, data) {
      try {
        commit("SET_LOADING", true);

        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/payment/getCards`,
            {
              customer: { ...data },
              method: "pagarme5",
            }
          );

        commit("SET_CARD_LIST", response.data);
      } catch (error) {
        console.log("[searchCardList]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async removeCard({ commit }, data) {
      try {
        commit("SET_LOADING", true);

        const response = await window
          .$request()
          .post(
            `/${
              window.$platform || window.$getCookie("platform")
            }/payment/deleteCard`,
            {
              customer: { ...data.customer },
              method: "pagarme5",
              id: data.card_id,
            }
          );

        window.$toast({
          color: "success",
          message: "Cartão removido com sucesso!",
        });

        commit("SET_CARD_LIST", response.data);
      } catch (error) {
        console.log("[searchCardList]", error);
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
  modules: {},
};
