import formValidator from "./validators/form";
import store from "./store";
import currency from "./transformers/currency";
import filters from "./filters";
import updateAll from "./update-all";
import defaultImage from "./default-image";

/**
 * Registra todos o utils no app Vue
 */
export const useUtils = (app) => {
  formValidator(app);
  store(app);
  currency(app);
  filters(app);
  updateAll(app);
  defaultImage(app);
};
