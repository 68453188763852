export default {
  namespaced: true,

  state: {
    filters: {},
  },

  getters: {
    getFilters: (state) => state.filters,
  },

  mutations: {
    SET_FILTERS: (state, { slot, value }) => {
      if (state.filters[slot] === undefined) {
        state.filters[slot] = {};
      }

      Object.keys(value).forEach((name) => {
        state.filters[slot][name] = value[name];
      });
    },
    SET_FILTER: (state, { slot, name, value, toggle }) => {
      if (state.filters[slot] === undefined) {
        state.filters[slot] = {};
      }

      if (toggle && state.filters[slot][name] === value) {
        delete state.filters[slot][name];
      } else {
        state.filters[slot][name] = value;
      }
    },
  },

  actions: {
    setFilters({ commit }, { slot, value }) {
      commit("SET_FILTERS", { slot, value });
    },

    removeFilters({ commit }, { slot, value }) {
      commit("REMOVE_FILTERS", { slot, value });
    },

    setFilter({ commit }, { slot, name, value, toggle }) {
      commit("SET_FILTER", { slot, name, value, toggle });
    },
  },
};
